<!--认证信息入口文件-->
<template>
  <div id="informationLayout">
    <div class="main">
      <transition name="slide-fade">
        <keep-alive :exclude="exclude">
          <router-view class="view"></router-view>
        </keep-alive>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      exclude: ["peopleManagement",'matchingSet'],
    };
  },
};
</script>


<style scoped>
#informationLayout {
  width: calc(100% - 30px);
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
}
.main {
  position: relative;
  width: 100%;
  height: 100%;
}
.view {
  position: absolute;
  width: 100%;
  height: 100%;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>